import React , {useState} from "react";
import background from "../../Assets/background3.jpg";
import { FiArrowRight } from "react-icons/fi";
import { BsFillPlayCircleFill } from "react-icons/bs";
import YouTubeModal from "./YouTubeModal";

const Home = () => {
 
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

 
  return (
    <div id="home" className="home-container ">
      <div className="home-banner-container">
        <div className="home-text-section" style={{ marginTop: "3em" }}>
          <h1 className="primary-heading heading-home ">
            Simplify your signing with IMZA
          </h1>
          <p className="primary-text text-home">
            The fastest, most secure and reliable electronic signature solution
            in Algeria.
          </p>
          <div className="flex gap-6 p-4 home-buttons ">
            <a
              href="https://web.imza.dz/login"
              className="secondary-button flex gap-4"
            >
              <span> Try it Now </span>{" "}
              <FiArrowRight style={{ marginLeft: "10px", color: "white" }} />{" "}
            </a>
            <button className="secondary-button" onClick={handleOpenModal}>
              <BsFillPlayCircleFill style={{ marginRight: "10px" }} />
              {/* <a style={{color:"white"}} href="https://www.youtube.com/watch?v=RxUM09lskbE" target="_blank" rel="noopener noreferrer"> */}
        Watch Video
      {/* </a> */}
      <YouTubeModal
        videoId="n4pZ3e2-aF8" 
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      />
            </button>
          
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Home;
