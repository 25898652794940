import React, { useRef, useEffect } from 'react';
import { Modal } from 'bootstrap';

const YouTubeModal = ({ videoId, isOpen, onClose }) => {
  const modalRef = useRef(null);
  const iframeRef = useRef(null);

  useEffect(() => {
    const modalElement = modalRef.current;
    const bsModal = new Modal(modalElement);

    if (isOpen) {
      bsModal.show();
    } else {
      bsModal.hide();
    }

    const handleHide = () => {
      // Reset the iframe src to restart the video
      iframeRef.current.src = `https://www.youtube.com/embed/${videoId}`;
      onClose();
    };

    modalElement.addEventListener('hidden.bs.modal', handleHide);

    return () => {
      modalElement.removeEventListener('hidden.bs.modal', handleHide);
      bsModal.dispose();
    };
  }, [isOpen, onClose, videoId]);

  return (
    <div
      className="modal fade"
      ref={modalRef}
      tabIndex="-1"
      aria-labelledby="youtubeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-body">
            <div className="ratio ratio-16x9">
              <iframe
                ref={iframeRef}
                src={`https://www.youtube.com/embed/${videoId}`}
                title="YouTube video"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ border: 0 }}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YouTubeModal;
