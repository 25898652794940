import React, { useState, useEffect } from "react";
import eIDAS from "../../Assets/team.png";
import template from "../../Assets/web-design.png";
import role from "../../Assets/project-manager.png";
import legal from "../../Assets/legal.png";
import file from "../../Assets/file.png";
import notification from "../../Assets/time.png";
import library from "../../Assets/online-library.png";
import job from "../../Assets/permission.png";
import password from "../../Assets/copy.png";
import expand from "../../Assets/expand-arrows.png";
import AOS from "aos";

const Features = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    AOS.init({ duration: 290 });
  }, []);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isSmall = windowWidth <= 576;
  const isbig = windowWidth >= 1000;
  const Data = [
    {
      image: file,
      title: "Download all types of documents",
      width: "8em",
    },
    {
      image: notification,
      title: "Set document access duration",
      width: "8em",
    },
    {
      image: eIDAS,
      title: "Multi-Recipient Signing",
      width: "8em",
    },
    {
      image: library,
      title: "Store documents in an online library",
      width: "8em",
    },
    {
      image: job,
      title: "Configure document access rights",
      width: "8em",
    },
    {
      image: legal,
      title: "Require legal consent before signing",
      width: "8em",
    },
    {
      image: template,
      title: "Create reusable workflow templates",
      width: "8em",
    },
    {
      image: password,
      title: "Replicate signature fields",
      width: "8em",
    },
    {
      image: role,
      title: "Configure recipient roles in the workflow",
      width: "8em",
    },
  ];

  return (
    <div id="features" className="features-wrapper container">
      <div className="features-title text-center my-4">
        <h1>Most popular features</h1>
      </div>
      <div
        className={`features-section-bottom row ${
          isSmall ? "flex-column" : "flex-row"
        }`}
      >
        {Data.map((data) => (
          <div
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-delay="300"
            data-aos-offset="0"
            className={`features-section-info col ${
              isSmall ? "col-12" : isbig ? "col-3" : "col-4"
            }`}
            key={data.title}
          >
            <div className="info-boxes-img-container-features text-center">
              <img src={data.image} style={{ width: data.width }} alt="" />
            </div>
            <div className="text-center mt-2">
              <h2>{data.title}</h2>
            </div>
          </div>
        ))}
      </div>
      <div className="center-container text-center my-4">
        <h4>See More Features</h4>
        <img src={expand} alt="" />
      </div>
    </div>
  );
};

export default Features;
