import "./App.css";
import { MainRouter } from "./Constants/routes";
import Homepage from "./Pages/HomePage";
import { RouterProvider } from "react-router-dom";

function App() {
  return (
    <>
    <Homepage/>
      {/* <RouterProvider router={MainRouter} />{" "} */}
    </>
  );
}

export default App;
