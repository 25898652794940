import React, { useState, useEffect } from 'react';
import upload from "../../Assets/upload (2).png";
import send from "../../Assets/send-data.png";
import recieve from "../../Assets/email.png";
import AOS from 'aos';

const Work = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {
    AOS.init({ duration: 200 });
  }, []);

  const isHidden = windowWidth <= 1000;
  const isSmall = windowWidth <= 576;
  const workInfoData = [
    {
      image: upload,
      title: "Upload",
      text: "Upload your document directly to IMZA.",
    },
    {
      image: send,
      title: "Prepare & Send",
      text: "Add recipients and send your document for signing.",
    },
    {
      image: recieve,
      title: "Signed Document",
      text: "Receive notifications as soon as the document is signed.",
    },
  ];
  
  return (
    <div  id="work" className="work-wrapper container">
    <div className="work-section-wrapper">
        <div className="work-section-top">
            <h1 className="primary-heading">How does it work?</h1>
            <p className="primary-text" style={{ fontSize: "1.5em" }}>
                A simple 3-step process
            </p>
        </div>
        <div  className="work-section-bottom flex">
            {workInfoData.map((data) => (
                <div data-aos="flip-right" className={`work-section-info col ${isHidden  ? 'col-md-12' : 'col-md-3'} `} key={data.title}>
                    <div className="info-boxes-img-container">
                        <img src={data.image} style={{ width: "70px" }} alt="" />
                    </div>
                    <h2>{data.title}</h2>
                    <p>{data.text}</p>
                </div>
            ))}
        </div>
    </div>
</div>
  );
};

export default Work;
