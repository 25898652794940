import React from "react";
const Contact = () => {
  return (
    <div id="support" className="contact-page-wrapper d-flex flex-column justify-content-center align-items-center text-center ">
    <div className="primary-heading-container">
        <h1 className="primary-heading">Do You Have Any Questions?</h1>
    </div>
    <button
        className="secondary-button-help "
        onClick={() => window.location.href = 'mailto:Contact@a-tdd.net'}
    >
        Contact Us
    </button>
</div>
  );
};

export default Contact;
