import React, { useState } from "react";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import Logo from "../Assets/logo-imza.svg";
import slogo from "../Assets/imza-text-3.svg";
import { HiOutlineBars3 } from "react-icons/hi2";

const CustomNavbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
   
    <Navbar expand="lg" fixed="top" className="nav-container">
      <Navbar.Brand href="https://www.imza.dz/">
        <div className="" style={{ width: "10vh", marginLeft: "2rem" }}>
          <img src={Logo} alt="logo" className="d-inline-block align-top" />
        </div>
      </Navbar.Brand> 
       <Navbar.Toggle aria-controls="basic-navbar-nav" style={{marginRight:"1em"}}>
        <HiOutlineBars3 className="text-2xl " />
      </Navbar.Toggle>

      <Navbar.Collapse id="basic-navbar-nav" className="navbar-links-container">
        <Nav className="ml-auto">
          <Nav.Link href="#home">
            <span>Home</span>
          </Nav.Link>
          <Nav.Link href="#features">
            <span>Features</span>
          </Nav.Link>
          <Nav.Link href="#about">
            <span>About Us</span>
          </Nav.Link>
          <Nav.Link href="#support">
            <span>Support</span>
          </Nav.Link>

          <Nav.Link href="https://web.imza.dz/login">
            {" "}
            <button className="primary-button text-white mr-2"> Log In</button>{" "}
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>

     
    </Navbar>
  );
};

export default CustomNavbar;
