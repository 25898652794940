import React from "react";
import Logo from "../Assets/logo.svg";
import youtube from "../Assets/youtube.png";
import facebook from "../Assets/facebook.png";
import linkedin from "../Assets/linkedin.png";
import twitter from "../Assets/twitter.png";

const Footer = () => {
  const images = [
    {
      title: youtube,
      src: "https://www.youtube.com/@IMZAe-signature"
    },
    // {
    //   title: facebook,
    // },
    {
      title: linkedin,
      src: "https://www.linkedin.com/company/imzaesignature/"
    },
    // {
    //   title: twitter,
    // },
  ];
  return (
    <>
     <div className="footer-wrapper   py-4">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <div className="footer-logo-container">
                            <img src={Logo} style={{ width: "100px" }} alt="Logo" />
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="footer-section-columns">
                                    <a href="#home">Home</a>
                                    <a href="#work">How does it work?</a>
                                    <a href="#about">What is IMZA?</a>
                                    <a href="#support">You need help?</a>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="footer-section-columns">
                                    <span>Help</span>
                                    <span>Share</span>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="footer-section-columns">
                                    <a  onClick={() => (window.location.href = "mailto:Contact@a-tdd.net")}>
                                        Contact@a-tdd.net
                                    </a>
                                    <a onClick={() => (window.location.href = "mailto:Support@a-tdd.net")}>
                                        Support@a-tdd.net
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="footer-section-columns">
                                    <a href="https://web.imza.dz/terms" target="_blank" rel="noopener noreferrer">
                                        Terms of Service
                                    </a>
                                    <a href="https://web.imza.dz/privacy" target="_blank" rel="noopener noreferrer">
                                        Privacy Policy
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-section-one py-4">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="d-flex gap-3 flex-md-row align-items-center justify-content-center justify-content-md-start mb-3 mb-md-0">
                            <p className="mb-0 mr-md-3">Follow us on:</p>
                            {images?.map((img, index) => (
                                <div key={index} className="mr-md-3">
                                  <a target="_blank" href={img.src}>
                                  <img src={img.title} style={{ width: "30px" }} alt="" />
                                  </a>  
                                    
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="language-select">
                            <select name="language" id="language" className="form-select">
                                <option value="fen">English</option>
                                {/* Add more language options if needed */}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="footer-copyright text-center text-md-end">
                            <p className="mb-0">Copyright © 2024 ATDD. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};

export default Footer;
