import React,{ useState, useEffect } from "react";
// import AboutBackground from "../../Assets/about.jpg";
import AboutBackground from "../../Assets/background4.jpg.png";
import { PathConstants } from "../../Constants/paths";
// import { useNavigate } from "react-router";

const About = () => {
  // const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isHidden = windowWidth <= 1000;
  const nextpage = () => {
    // navigate(PathConstants?.About);
  };
  return (
    <div id="about" className="about-section-container container">
    <div className="row">
        {!isHidden && (
            <div className="col-md-6 about-background-image-container">
                <img src={AboutBackground} style={{ borderRadius: '300px' }} alt="" />
            </div>
        )}
        <div className={`col-12 ${!isHidden ? 'col-md-11' : ''} about-section-text-container`}>
            <h1 className="primary-heading">What is IMZA?</h1>
            <p className="primary-text about-text">
                IMZA offers you an electronic signature solution designed to simplify
                and secure your signing processes. Whether for personal use or
                corporate needs, Imza offers you an intuitive and robust platform
                for all your electronic transactions.
            </p>
            <div className="about-buttons-container">
                <button onClick={nextpage} className="secondary-button">
                    More Information
                </button>
            </div>
        </div>
    </div>
</div>
  );
};

export default About;
