import React, { useEffect } from "react";
import Navbar from "../Components/Navbar";
import Home from "../Components/home/Home";
import About from "../Components/home/About";
import Features from "../Components/home/Features";
import Work from "../Components/home/Work";
import Footer from "../Components/Footer";
import Contact from "../Components/home/Contact";
const Homepage = () => {
  useEffect(() => {
    const handleScroll = () => {
      const backToTopButton = document.getElementById("backToTop");
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        backToTopButton.style.display = "block";
      } else {
        backToTopButton.style.display = "none";
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
  };
  return (
    <div className="" style={{ padding: "0px" }}>
      <Navbar />

      <div className="App">
        <Home />
        <div class="back-to-top" id="backToTop" onClick={scrollToTop}>
          ↑
        </div>

        <About />
        <Work />
        <Features />
        {/* <Billing/> */}
        <Contact />
      </div>
      <Footer />
    </div>
  );
};
export default Homepage;
